import React, { useContext, useState, useEffect } from "react"

const QRContext = React.createContext()

export function useQR() {
    return useContext(QRContext)
}

export function QRProvider({ children }) {
    const [qrCode, setQrCode] = useState()
    const [loading, setLoading] = useState(true)
    const [qrLoadError, setQrLoadError] = useState("")
    const [redirecting, setRedirecting] = useState(true)

    const value = {
        qrCode,
        setQrCode,
        loading,
        setLoading,
        qrLoadError,
        setQrLoadError,
        redirecting,
        setRedirecting
    }

    return (
        <QRContext.Provider value={value}>
          {children}
        </QRContext.Provider>
      )
}