import React from "react"

export default function Privacy() {


    return (
        <>
        <div className="text-left">
            <h2 className="text-center mb-4">Privacy Policy</h2>
            <h5 className="text-center mb-4">Last Updated: June 2023</h5>
            <p>We at QROW (“QROW,” “we,” “us,” or “our”) have created this privacy policy (this “Privacy Policy”) because we know that you care about how information you provide to us is used and shared. This Privacy Policy relates to the information collection and use practices of QROW in connection with our Platform. By visiting our Platform, Visitors and Registered Users are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use. Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.</p>
            <h5>The Information We Collect</h5>
            <p>In the course of operating the Platform, we may collect (and/or receive) the following types of information. You authorize us to collect and/or receive such information.</p>
            <ol>
                <li>Personal Information
                    <p>When you sign up to become a Registered User, you will be required to provide us with personal information about yourself, such as your name, e-mail address, location, and optionally your phone number. If you contact us, you will need to provide your name and registered e-mail address or mobile number. Registered Users may also provide other identifiable information in the course of using the Platform. All information we collect and/or receive under this section is collectively called “Personal Information”. We only share the personal information that you accept, agree and request to be shared according to the permission prompts a user is provided throughout the Site or Platform. We do not share Personal Information with any 3rd party without your permission, agreement or acceptance. We do not collect, store and share any Personal Information from Visitors or Registered Users unless they provide such information voluntarily.</p>
                </li>
                <li>Billing Information
                    <p>If you purchase any Features or Services, you will be required to provide certain information to our third-party payment processor, Apple Pay or other payment processing systems which may be used. Such information may include a debit card number, credit card number, expiration date, billing address, activation codes, and similar information. Such information is collectively called the “Billing Information.” You authorize Apple Pay and/or any other certified payment system we use, to collect, process, and store your Billing Information in accordance with its terms of service and privacy policy which can be found here: <a href="If you purchase any Features or Services, you will be required to provide certain information to our third-party payment processor, Apple Pay or other payment processing systems which may be used. Such information may include a debit card number, credit card number, expiration date, billing address, activation codes, and similar information. Such information is collectively called the “Billing Information.” You authorize Apple Pay and/or any other certified payment system we use, to collect, process, and store your Billing Information in accordance with its terms of service and privacy policy which can be found here: https://www.apple.com/legal/applepayments/direct-payments
QROW does not store or process your Billing Information."> https://www.apple.com/legal/applepayments/direct-payments.</a> QROW does not store or process your Billing Information.</p>
                </li>
                <li>Other Information
                    <p>In addition to the information noted above, we may collect additional information (collectively, the “Other Information”). Such Other Information may include:</p>
                    <ul>
                        <li><p>From You. Additional information about yourself that you voluntarily provide to us, such as job title, company or product information, service preferences, and other information that does not identify you personally.</p></li>
                        <li>From Your Activity. Information that we automatically collect when you use the Platform, including, without limitation:
                            <ol>
                                <li>IP addresses, which may consist of a static or dynamic IP address and will sometimes point to a specific identifiable computer or device; browser type and language; referring and exit pages and URLs; date and time; amount of time spent on particular pages; what sections of the Site you visit; and similar data; and</li>
                                <li>Information about your device, including the type of device; universally unique ID (“UUID”); advertising identifier (“IDFA”); MAC address; operating system and version (e.g., iOS, Android or Windows); carrier and country location; hardware and processor information (e.g., storage, chip speed, camera resolution, NFC enabled); network type (WiFi, 3G, 4G, LTE); and similar data.</li>
                            </ol>
                        </li>
                        <li>From Cookies. Information that we collect using “cookie” technology. Cookies are small packets of data that a website stores on your computer’s or mobile device’s hard drive so that your computer will “remember” information about your visit. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to help us collect Other Information and to enhance your personalized experience using the Platform. If you do not want us to place a cookie on your hard drive, you may be able to turn that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the Platform may not function properly.</li>
                    </ul>
                </li>
                <li>Third-Party Analytics
                    <p>We use third-party analytics services (such as Google Analytics) to evaluate use of the Platform, compile reports on activity, collect demographic data, analyze performance metrics, geolocate, and collect and evaluate other information relating to the Platform and mobile and Internet usage. These third parties use cookies and other technologies to help analyze and provide us the data. By accessing and using the Platform, you consent to the processing of 
      data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy. For more information on Google Analytics, including how to opt out from certain data collection, please visit https://www.google.com/analytics. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the Platform.
</p>
                </li>
                <li>Information Collected by or Through Third-Party Advertising Companies
                    <p>We may share Other Information about your activity on the Platform with third parties for the purpose of tailoring, analyzing, managing, reporting, and optimizing advertising you see on the Platform and elsewhere. These third parties may use cookies, pixel tags (also called web beacons or clear gifs, and/or other technologies to collect such Other Information for such purposes. Pixel tags enable us, and these third-party advertisers, to recognize a browser’s cookie when a browser visits the site on which the pixel tag is located in order to learn which advertisement brings a user to a given site.</p>
                </li>
                <li>Accessing and Modifying Personal Information and Communication Preferences
                    <p>If you are a Registered User, you may access, review, and make changes to your Personal Information, certain Other Information, and, if applicable, Billing Information by following the instructions found on the Platform. In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the “unsubscribe” link located on the bottom of any QROW marketing email. Registered Users cannot opt out of receiving transactional e- mails related to their account. We will use commercially reasonable efforts to process such requests in a timely manner. You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases.</p>
                </li>
                <li>How We Use and Share the Information
                    <p>We use the Personal Information, the Transaction Information, and the Other Information (collectively, the “Information”) to provide, maintain, and improve the Platform; to solicit your feedback; and to inform you about our products and services and those of our third-party marketing partners.
We may also use and/or share Information as described below.</p>
                    <ol>
                        <li>QROW will access, use, and share the Information as required to provide support to you.</li>
                        <li>In order to provide the Platform, we may share the Information with our third-party promotional and marketing partners, including, without limitation, businesses participating in our various programs.</li>
                        <li>We may, from time to time, share and/or license Information to other companies who may provide you information about the products and services they or their partners offer. However, to the extent required by law, you will be given the opportunity to opt out of such sharing.</li>
                        <li>We may employ other companies and individuals to perform functions on our behalf. Examples may include providing technical assistance, customer service, and marketing assistance. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law.</li>
                        <li>In an ongoing effort to better understand our Visitors, Registered Users, and Platform, we may analyze Information in aggregate form. This aggregate information does not identify you personally. We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our Platform to current and prospective business partners and to other third parties for other lawful purposes.</li>
                        <li>We may share some or all of your Information with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us.</li>
                        <li>As we develop our businesses, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, the Information may be part of the transferred assets.</li>
                        <li>To the extent permitted by law, we may also disclose the Information: (i) when required by law, court order, or other government or law enforcement authority or regulatory agency; or (ii) whenever we believe that disclosing such Information is necessary or advisable, for example, to protect the rights, property, or safety of QROW or others.</li>
                    
                    </ol>
                </li>
                <li>Information You Share
                    <p>Please keep in mind that whenever you voluntarily make your Personal Information or Registered User Content available to third parties — for example in QROW Rooms, on message boards or web logs; through email; or in comment or chat areas — that information and content can be seen, collected, heard, and/or used by others besides us. We cannot be responsible for any unauthorized third-party use of such information or content, and this Privacy Policy does not apply to any Information that you share in any of the foregoing ways.
</p>
                </li>
                <li>How We Protect the Information
                    <p>We take commercially reasonable steps to protect the Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that the Information that you supply will not be intercepted while being transmitted to and from us over the Internet. In particular, e-mail sent to or from the Site may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</p>
                </li>
                <li>Important Notice to Non-U.S. Residents
                    <p>It is important to note that the Platform and its servers are operated in the United States and in other countries. If you are located outside of the United States, please be aware that any Information you provide to us will be transferred to the United States. By using the Platform or by providing us Information when doing so, you hereby irrevocably consent to this transfer and our use of the Information and data provided by you in accordance with this Privacy Policy.</p>
                </li>
                <li>Children
                    <p>We do not knowingly collect Personal Information from children under the age of 17 through the Platform. If you are under 17, please do not give us any Personal Information. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children to never provide Personal Information through the Platform without their permission. If you have reason to believe that a child under the age of 17 has provided Personal Information to us, please contact us, and we will endeavor to delete that information from our databases.</p>
                </li>
                <li>California Residents
                    <p>Under California Civil Code Section 1798.83, California residents who have an established business relationship with QROW may choose to opt out of our sharing your Personal Information with third parties for direct marketing purposes. If you are a California resident and (1) you wish to opt out; or (2) you wish to request certain information regarding our disclosure of your Personal Information to third parties for the direct marketing purposes, please send an e- mail to privacy@qrow.com with “PRIVACY POLICY” in the subject line or write to us at: QRow, Inc.
QROW does not monitor, recognize, or honor any behavioral advertising opt-out or do not track mechanisms, including general web browser “Do Not Track” settings and/or signals.</p>
                </li>
                <li>External Websites & 3rd Party API’s/SDK’s
                    <p>The Platform may contain links to third-party websites, API’s and/or SDK’s. We have no control over the privacy practices or the content of any of our business partners, advertisers, sponsors, or other websites to which we provide links. As such, we are not responsible for the content or the privacy policies of those third-party websites. You should check the applicable third-party privacy policy and terms of use when visiting any other websites, API or SDK providers.</p>
                </li>
                <li>Changes to This Privacy Policy
                    <p>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time. By accessing the Platform and/or using our services after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.</p>
                </li>
                <li>How to Contact Us
                    <p>If you have questions about this Privacy Policy, please contact us via e-mail at privacy@qrow.com with “Privacy Policy” in the subject line.</p>
                </li>
            </ol>
            
            </div> 
        </>
    )

 
}