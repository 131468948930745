import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext"


const VideoItem = (param) => {
    const [playing, toggle] = useState(false); 
   
    const { playNextAsset, playingAsset, setPlayingAsset, isPlayMediaFinished, setPlaying } = useAuth()
    //let title = param.asset.url.split("/").pop().split("-").shift() 

    useEffect(() => { 

        if(playNextAsset ){
            if (param.asset._id !== undefined && param.asset._id === playNextAsset.id)  playVideo()
            else toggle(false)
        }

    }, [playNextAsset]);

    useEffect(() => { 
        
        if ( param.asset._id === playingAsset._id) toggle(true)
        else toggle(false)

    }, [playingAsset]);


    useEffect(() => { 
        if (isPlayMediaFinished !== "" && playingAsset._id === param.asset._id) toggle(false)  
        
    }, [isPlayMediaFinished]);

    const playVideo = () => {  

        if(!playing && playingAsset._id !== param.asset._id){ 
            setPlayingAsset(param.asset) 
            setPlaying(true)
        }
        else setPlaying(!playing)

        toggle(!playing) 
        
    }

    return (
        <div className="videoItem" onClick={playVideo}>
            <div className="imageItem" >
                {param.asset.contentType.indexOf("video") >= 0 ?
                    <img src={param.asset?.urlSmall} alt="image is not found" /> 
                    :  <img src={require("../assets/wave.png")} alt="image is not found" /> 
                } 
            </div>
            <div className="center">
                {playing ? <img src={require("../assets/pause-button.png")} alt="image is not found" width={50} /> : 
                <img src={require("../assets/play-circle.png")} alt="image is not found" width={50} />}
            </div>
           
        </div>
    );
};
  
export default VideoItem;