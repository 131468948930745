import React, {useState, useEffect } from 'react'
import '../styles/Header.css'
import { Link,  } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { useNavigate } from "react-router-dom" 

export default function Header() {

    const { logout } = useAuth()
    const navigate = useNavigate()
    
    const loggedInUser = localStorage.getItem("user"); 
    const [logoStyle, setLogoStyle] = useState("logo")
    const superuser = localStorage.getItem("superuser"); 

    async function handleLogout() {
        
        try {
          await logout()
          navigate("/login")
        } catch {
          
        }
    }
 
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);  

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
      }, []);

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 500) {
            setLogoStyle("logo-mobile")
        }
        else {
            setLogoStyle("logo")
        }

      }

    return (
        <div className="">
            <nav className="nav ">
                <Link to="/qr" className="site-title "> 
                    <img className={logoStyle} src={require("../assets/qrow-logo-full.png")} alt="logo not found" width={250}/>
                </Link>
            
                { <ul className="header-nav">  
                    
                    {  !loggedInUser && <li> <Link to="/login"> Log In </Link> </li> }
                    {  !loggedInUser && <li> <Link to="/signup"> Sign Up </Link> </li>  }
                    {  superuser && <li > <Link to="/dashboard" > Dashboard </Link> </li> }
                    {  loggedInUser && <li > <Link to="/signup" onClick={handleLogout}> Log Out </Link> </li> }
                </ul> }
            
            </nav> 
        </div>
    )
}
