import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import App from './App'; 
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"
import { AuthProvider } from './contexts/AuthContext';
import { Routes, Route } from "react-router-dom"
import { QRProvider } from './contexts/QRContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <QRProvider>
          <Routes>
            <Route path="/qr/:id/*" element={<App/> }/>
            <Route path="*" element={<App/>} /> 
          </Routes>
        </QRProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
 