import { Alert, Card } from "react-bootstrap" 
import {useParams} from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'
import { useAuth } from "../contexts/AuthContext"
import { useQR } from "../contexts/QRContext"
import Loader from './Loader' 
import ImageItem from "./ImageItem"
import VideoPlayer from "./VideoPlayer"
import VideoItem from "./VideoItem"
import Lightbox from "yet-another-react-lightbox";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow"; 
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Modal from 'react-bootstrap/Modal';

export default function QRcode() {

  const { setQRCodeID, isPlayMediaFinished, setPlayingAsset, playingAsset, setPlayNextAsset } = useAuth();
  const {id} = useParams();
  const { redirecting, qrLoadError, setQrLoadError, loading, setLoading, qrCode } = useQR()
 
  const [showMedia, setShowMedia] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [startImageIndex, setStartImageIndex] = useState(-1);
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => { 

    if (qrCode != null) {
      setQRCodeID(qrCode._id)
      displayQRContent(qrCode)
      setLoading(false)
    }
    
    if (!redirecting) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode])

  useEffect(() => {

    if(isPlayMediaFinished !== "" ){  

        for (let index = 0; index < mediaList.length; index++) {
          const element = mediaList[index]; 
          
          //console.log("QRCode: mediaList playingAsset._id ", playingAsset._id)
          if(element._id === playingAsset._id){ 
 
            let nextAsset = mediaList[index+1]; 
            //console.log("QRCode: mediaList nextAsset ", nextAsset)
            if(nextAsset){
              setTimeout(() => {
                setPlayNextAsset(nextAsset)
              }, 1000)
            } 
            else {
              setPlayingAsset({})
              setPlayNextAsset({})
              setShowMedia(false)
            }
            break;
          }
          
        }
       
    }
  }, [isPlayMediaFinished])
 
  const formatDate = (date) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
     
    return `${hours}:${minutes}, ${day} ${month} ${year}`;
  };

  const resetAssetList = () => {
    setAssetList(prevItems => { 
      return [];
    });  

    setImageList(prevItems => { 
      return [];
    });

    setMediaList(prevItems => { 
      return [];
    }); 
  }

  const displayQRContent = (code) => {
    resetAssetList();

    const date = new Date(code.createdAt);
    let stringDate = formatDate(date);
    
    setCreatedAt(stringDate)
     
    if (code.qrCodeType === "post" && code.assets.length > 0 && assetList.length === 0) {
      code.assets.forEach(asset => {
        let title = asset.url.split("/").pop().split("?")[0]

        setAssetList(prevItems => { 
          return [...prevItems, {_id: asset._id ,url:asset.url, title: title}];
        });  
        
        if (asset.contentType.indexOf("image") >= 0) { 

          let image = {_id: asset._id, src:asset.url, alt: title, srcSet:[{src: asset.urlSmall, width: 300, height: 300 }, {src: asset.urlMedium, width: 600, height: 600 }, {src: asset.urlLarge, width: 900, height: 900 }]};
          setImageList(prevItems => { 
            return [...prevItems, image];
          });
        }
        else {
          setMediaList(prevItems => { 
            return [...prevItems, {_id: asset._id ,url:asset.url, title: title, contentType:asset.contentType}];
          }); 
         
        }
      }); 
    }
  }


  const displayImage  = (assetId) => {   
    console.log("displayImage assetId ", assetId)

  
    for (let index = 0; index < imageList.length; index++) {
      const element = imageList[index]; 
      if (element._id === assetId){ 
        setStartImageIndex(index)
      } 
    } 
  }

  const onCloseMediaModal = () => {
    setShowMedia(false); 
    setPlayingAsset({});
  }

  const getProfileInitial = () => {
    if (qrCode?.ownerProfile.firstName && qrCode?.ownerProfile.lastName) {
      return qrCode?.ownerProfile.firstName.substring(0, 1) + qrCode?.ownerProfile.lastName.substring(0, 1)
    }
    return "Unknown"
  }

  const getProfileName= () => {
    if (qrCode?.ownerProfile.firstName && qrCode?.ownerProfile.lastName) {
      return qrCode?.ownerProfile.firstName +" "+ qrCode?.ownerProfile.lastName
    }
    return "Unknown"
  }

  const getProfileUserName = () => {
    if (qrCode?.ownerProfile.userName) {
      return qrCode?.ownerProfile.userName
    }
    return "Unknown"
  }

  return ( 
    <>
      { 
        loading ? <Loader/> : 
        redirecting ? null :
        qrLoadError != "" ?  <Alert variant="danger">{qrLoadError}</Alert> : 
        qrCode == null ? null :
          <div>
            <Card  
              className="mb-10 text-center"
            >
              <Card.Header>{qrCode?.name}</Card.Header> 
              <Card.Body>
               
                <div className="qrContent">
                  {
                    qrCode?.qrCodeType === "link" ?
                     <a href={qrCode?.content} target="_blank">{qrCode?.content}</a>  
                    : <p>{qrCode?.content}</p>
                  }
                </div> 
              
                {
                  qrCode?.assets ?  
                  <div className="assetContainer">
                    
                    <ul className="assetList"> 
                      { qrCode?.assets.map(function(asset, index){
                          return  (asset.contentType.indexOf("image") >= 0 ?  
                                <li key={index} onClick={() => displayImage(asset._id)} ><ImageItem asset={asset} total={qrCode?.assets.length}/></li>
                              : <li key={index} onClick={() => setShowMedia(true)}><VideoItem asset={asset} total={qrCode?.assets.length}/></li>)  
                        })

                      }
                    </ul>
                  </div> :
                  null
                }
                <div className="qrCreator">
                  <div className="creator-profile"> 
                    {qrCode?.ownerProfile.avatarUrl != "" && qrCode?.ownerProfile.avatarUrl != null ?
                      <img
                        width={50}
                        height={50}
                        src={qrCode?.ownerProfile.avatarUrl}
                        className='creator-avatar'
                        alt=''
                      />
                    :
                      <span className="creator-initial">
                        <h5>
                          {getProfileInitial()}
                        </h5>
                      </span> 
                    }
                    <div className="creator-name"> 
                      <p>@{getProfileUserName()}</p>
                      <h6>{getProfileName()}</h6> 
                    </div>

                  </div>
                 
                  <div className="createdAt">
                    
                    <p>{createdAt}</p>
                  </div>

                </div>
              </Card.Body>
            </Card>
          
            <Lightbox  
            open={startImageIndex >= 0}
            slides={imageList} 
            close={() => setStartImageIndex(-1)} 
            index ={startImageIndex}
            carousel={{ finite: imageList.length <= 1 }}
            render={{
              buttonPrev: imageList.length <= 1 ? () => null : undefined,
              buttonNext: imageList.length <= 1 ? () => null : undefined,
            }} 
            plugins={[Fullscreen, Slideshow, Zoom, Counter]}
            counter={{ style: { top: 1, bottom: "unset" } }}
            /> 
             

            <Modal  
              show={showMedia}  
              onHide={() => onCloseMediaModal()}>
              <Modal.Header closeButton>
                <Modal.Title>Playing QR Asset</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {
                  <VideoPlayer/>
                }
               
              </Modal.Body>
            </Modal>

          </div>
      }
      {
        redirecting ? null : !loading  &&
          <div className="footer">
            <h6>Download QRow App On App Store</h6>
            <a href="https://apps.apple.com/sa/app/qrow/id6450970319" target="_blank">
              <img src={require("../assets/download-app-store.png")} alt="app store not found" width={200}/>
            </a>
          </div>
      }
    </>
  )
}
