


export const Config = {
    DEV: {
        APP_TOKEN : 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjJjOGJmNTcwYjQ1OTQ1ZjJhNDQ0N2M3MDI1NDYwN2ZhIiwic2NvcGUiOiJhcHAiLCJpYXQiOjE3MTU1OTAyOTR9.V_tPnhlPaQNiJAISjpTTn7PceqCk0fs9eVRy9uRDhFfsg4qFBHgbZbywsQnyKLzf_m1p0GjFFpxQ9YhDicbBCxxkpYhoE57_I5p6kwKEKhWBPWnQoXHo0fU3s4_m9gqa54BRePeADlZHD--di5zildsvcTCoQKvOGmDzR7hnsWVYm4u0En_cabGzx7Nwf5TqvOz7AIXSSN_wjQYMy42DeFIh_XsFuNcSkOEVlfE5aMZLWE9BvR_tWov9Uh0rcbeGBOSrugGkMu6MpXg_oaOC6rvi27K31O9RHBzBYNd7zcxYwtUKAjJLu0LoZ5l1z5HqYGpmtwzkFp48hd6A9m84L1fEitzFjr-O4XAFOpEQohvGFKH7ly2e_9umiA30ySviS7wa1OePkO4ppbl5xmri593Y9p4cCxC9SJCQtrije41uz6XU1gneGzczTa6omyTcl8IiZQ27XCe9v-XFMaF7gqf2PrCA-VToPgYSWHNajIrgtFLt6sIm4diP3c_d2eA2',
        REST_API : 'https://qrow-dev-server.azurewebsites.net',
        REALM_APP_ID : 'application-qrowv2-fbujpcr' ,
        GOOGLE_CLIENT_ID: '122176442185-71emee86mg9djsi2bmqfnj9do70ughtu.apps.googleusercontent.com',
        APPLE_LOGIN_BUNDLE_ID: 'com.skinux.qrow.web',
        APPLE_REDIRECT_URI: 'https://dev.qrow.io/login'
    },
    PROD : {
        APP_TOKEN : "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjgxZDc0Yjg5OTBiOTRlYmFhMTAxYTk1YjA3NGExZjcwIiwic2NvcGUiOiJhcHAiLCJpYXQiOjE3MTc0NjMzMjV9.NXpvhaDfvYN-0nK0R3lf_CzcaJ5IC1WH4Isdc-BxaK-T4wEnSLmP9zM_fNmKv4-mGjy95ODYyNoQxPM_KitdnuuChVCguBpbH58baG3lGYyo3fMLUDHlmxxB3OXj4hPazHKx193FE55gwynREuJLvRuMeqp7KvOd7da-VFmQ6-L1faQadNHLgE5iVArMZlh9i3VHMwd4_PhsVAbZj9Qt0fZmd1byuj8bA5Rij5TpBU3cDUMq5jdTcITksfW-3QFp5DQPqRHGOEldoadJP6f97nl86Eo2BbHFKUJXcLzTb-k-T4TqvNvfzLtPQzOtSpHeGv5_pQj1RonkAe-lkRBAY16l5uS8uyUsVsEWuf_3Nmk8ZKuegs4cCU0dJ988vuuJ6hmNjyk0V40K67BLnQHDrgGCH_DAYuCch1004IRLxQV3dsyA1gRXoIXP27VaKiKjE2nFLOpDOlZ_BChwhb3K2FRmbZhbb1xUNfOqpAPQhXyoqfn_qoxQX1RxCTxQVk72",
        REST_API : "https://qrow-prod-selfhosting.azurewebsites.net",
        REALM_APP_ID : "qrowv2-kwqvvkx",
        GOOGLE_CLIENT_ID: '122176442185-71emee86mg9djsi2bmqfnj9do70ughtu.apps.googleusercontent.com',
        APPLE_LOGIN_BUNDLE_ID: 'com.skinux.qrow.web',
        APPLE_REDIRECT_URI: 'https://qrow.io/login'
    }
}