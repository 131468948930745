 
import React, { useEffect, useState } from 'react'
import { useAuth } from "../contexts/AuthContext"
import Loader from './Loader' 
import { Alert, Card, Table } from "react-bootstrap" 
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom" 

export default function Dashboard() {
    const navigate = useNavigate()
    const {  realmUser } = useAuth()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("")
    const [totalAmount, setTotalUserAmount] = useState();
    const [activedPage, setActivedPage] = useState(1)  
    const [totalPage, setTotalPage] = useState(1)   
    let recordPerPage = 50;
    const superuser = localStorage.getItem("superuser"); 


    useEffect(() => {
    
        if (!superuser) {
            navigate("/qr");
            return;
        } 
        
        let isMounted = true; 
    
        setTimeout(() => { 
          isMounted  && fetchData();
        }, 1000)
        
    
        return () => {
          isMounted = false; 
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])



    const fetchData = async (search) => { 
        if (!superuser) {
            navigate("/qr");
            return;
        } 
       

        setLoading(true);
       
        try {   
            const result = await realmUser.functions.getUserPrivate(search, activedPage-1);
            let list = JSON.parse(result);  
            setTotalUserAmount(list.totalRecord)
            setUsers(list.users);   
            setTotalPage(Math.ceil(totalAmount/recordPerPage));
             
        } catch (error) {
            console.error(error)
            setError("Failed to load data")
        }
        finally{
            setLoading(false) 
            
        } 
       
    }

  

    const formatDate = (date) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${hours}:${minutes}, ${day} ${month} ${year}`;
    }; 

    const handleChange = async (event) => {

        try {
            const userId = event.target.id;
            const status = event.target.value;
            const myUsers = [...users];
            const user = myUsers.find(
              a => a.userId === userId
            );
            user.status = status;
            user.updatedAt = new Date();
            setLoading(true)
            await realmUser.functions.updateUserPrivate(userId, status);
            
            setUsers(myUsers);
        } catch (error) {
            
        }
        finally{
            setLoading(false)
        } 
    };

    const resetUser = () => {
        setSearch("");
        setActivedPage(1);
        fetchData()
    }

    const handleKeyDownSearch = (evt) => {
        if (evt.key === 'Enter') {
            fetchData(search)
        }
    }

    const handleChangeSearch = (evt) => {
        setSearch(evt.target.value);

        setUsers(users.map(user => {
            if (user.email && user.email.indexOf(evt.target.value) < 0) {
              // Create a *new* object with changes
              return { ...user, hide: true };
            } else {
                return { ...user, hide: false };
            }
        }));

        
    }

    const handleChangePage = ( evt, page ) => {
        console.log(page);

        setActivedPage(page);
    };
 
    return ( 
        <>

            <h5>Welcome to administrator Dashboard</h5>

            { 
            error !== "" ?  
            <Alert variant="danger">{error}</Alert> : 

            <div>
                <Card className="mb-12 text-center">
                <Card.Header>
                    
                        <div className='totalUser'>
                            <p>Total User: {totalAmount}</p>
                        </div>

                        <div className='searchBox'>
                            <TextField className='searchText' id="standard-basic" label="Search" variant="standard" value={search} onChange={handleChangeSearch} onKeyDown={handleKeyDownSearch}/>
                            <a href="#" onClick={resetUser}>RESET</a>
                            
                        </div>
                     
                </Card.Header> 
                <Card.Body>

                    <div className="assetContainer"> 
                    <Table responsive="sm">
                        <thead>
                        <tr key="qrocode">
                            <th>#</th>
                            <th>User Id</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>credits</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                             
                        </tr>
                        </thead>

                        <tbody>
                        { 
                            users.map(function(user, index){
                                if (!user.hide) { 
                                
                                return (
                                <tr key={index + 1}>
                                    <td>{index + 1}</td>
                                    <td>{user.userId}</td>
                                    <td>{user.email}</td>
                                    <td>

                                        <select id={user.userId} onChange={handleChange} value={user.status}>
                                            <option key="normal" value="normal">Normal</option>
                                            <option key="privileged" value="privileged">Privileged</option>
                                            <option key="blocked" value="blocked">Blocked</option>
                                        </select>

                                    </td>
                                    <td>{user.credits}</td>
                                    <td>{ formatDate(new Date(user.createdAt))}</td>
                                    <td>{ formatDate(new Date(user.updatedAt))}</td>
                                
                                </tr>
                                )
                                }
                            })
                        }
                        
                        
                        </tbody>
                    </Table>

                    
                    </div> 
                
                </Card.Body> 
               
                </Card>

                {totalPage > 1 ?  
                    <div className="pagination">
                        <Pagination count={totalPage} variant="outlined" onChange={handleChangePage} /> 
                    </div>
                    : null
                }
               
            </div>

           
            }

            { loading ? <Loader/> : null}
            
            
        </>

    )
}