 
import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext"
import ReactPlayer from "react-player"; 
import Duration from './Duration'
 

const VideoPlayer = (param) => {
    
    const { playingAsset, setPlayingAsset, setPlayMediaError, playNextAsset, setPlayNextAsset, playing, setPlaying, setPlayMediaFinish } = useAuth()
    const [url, setUrl] = useState()
    const [player, setPlayer] = useState()
    const [duration, setDuration] = useState(0)
    const [played, setPlayed] = useState(0)
    const [playedSound, setPlayedSound] = useState(false)

    useEffect(() => {
        if (playingAsset && playingAsset._id){
            setPlayMediaFinish("") 
            playVideo(playingAsset) 

            console.log("VideoPlayer useEffect playingAsset _id... ", playingAsset._id)
        }
    }, [playingAsset]);

    useEffect(() => {
        if(playNextAsset && playNextAsset._id ) playVideo(playNextAsset)
    }, [playNextAsset]);

    useEffect(() => {
        setPlaying(playing)
    }, [playing]);
 
    const playVideo = (video) => { 
        console.log("VideoPlayer playVideo... ", video._id) 

        if(video.url) {

            if (video.contentType && video.contentType.indexOf("audio") >= 0) setPlayedSound(true)
            else setPlayedSound(false)

            if(player) player.seekTo(parseFloat(0.0))
            setPlayNextAsset(null)
            setPlayMediaFinish("")
            setUrl(video.url) 
            setPlayingAsset(video)
            setPlaying(true) 
            setPlayMediaError(false)
        }
    } 

    const onEndedVideo = () => { 
        console.log("onEndedVideo playingAsset _id... ", playingAsset._id) 
        //setPlaying(false)
        setPlayMediaFinish(Math.random())
    } 

    const onErrorPlayVideo = (e) => { 
        console.error("onErrorPlayVideo: #####  ", e)
        setPlayMediaError(true)
        setPlaying(false)
    } 
 
    const onPlayVideo = () => { 
        setPlayed(true) 
    } 
    

    const handleProgress = (state) => {
        //console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        setPlayed(state.played)
    }
    
    const handleDuration = (duration) => {
        console.log('onDuration', duration)
        setDuration(duration)
    }

    const handlePlayPause = () => {
        setPlaying(!playing)
    }
     

    let ref = player => {
        setPlayer(player)
    }

    const handleSeekChange = e => {
        setPlayed(parseFloat(e.target.value))
    }

    const handleSeekMouseDown = e => {
     
    }

    

    const handleSeekMouseUp = e => { 
        player.seekTo(parseFloat(e.target.value))
    }
 

    return (
        <div className="videoPlayer">
        
            <ReactPlayer
                ref={ref}
                //light={<img src='{playingAsset.urlPreview}' alt='Thumbnail' />}
                width='100%'
                height='100%'
                url={url}
                playing={playing}  
                controls = {true} 
                onPlay = {onPlayVideo}
                onEnded = {onEndedVideo}
                onError =  {onErrorPlayVideo} 
                onProgress={handleProgress}
                onDuration={handleDuration}
             
            /> 

          
            <div className="soundControlers"  style={{visibility: playedSound ? 'visible' : 'hidden' }}>
                <button onClick={handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
                <br/> 
                <Duration seconds={duration * (1 - played)} /> 
                <input
                    type='range' min={0} max={0.999999} step='any'
                    value={played}
                    onMouseDown={handleSeekMouseDown}
                    onChange={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                />
            </div> 
            
        </div>
    );
};
  
export default VideoPlayer;