import React from 'react'

const ImageItem = (param) => { 
    
    //let title = param.asset.url.split("/").pop().split("-").shift()
    return (
        <div className="imageItem" >
            <img src={param.asset?.urlSmall} alt="image is not found" width={80}/>
            
        </div>
    );
};
 

export default ImageItem;
