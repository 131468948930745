import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Spinner } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom" 
import { Config } from "../config/Config"
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Login() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, logout, socialLogin, socialSignup, decodeJWTToken, qrCodeId} = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [persist, setPersist] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordType, setPasswordType] = useState("password")
  const navigate = useNavigate()
  let userData = {};
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("") 
  const initialized = useRef(false)
  const appleLoginSuccess = useRef(false)
  let config = process.env.REACT_APP_SERVER ===  'dev' ? Config.DEV : Config.PROD;


  useEffect(() => {

    const appleIDSignInOnFailure = (event) => {
      console.log("AppleIDSignInOnFailure.....", event.detail); 
      setError(`Failed to log in: ${event.detail.error}`); 
    }

    const appleIDSignInOnSuccess = (event) => { 
      // Listen for authorization success.
      // Handle successful response.
      console.log("appleIDSignInOnSuccess ... "); 
        
      // this event run twice for some reason. need only run once
      if (appleLoginSuccess.current) return;
      appleLoginSuccess.current = true;

      if (event.detail.authorization){
        userData.token = event.detail.authorization.id_token;
        let user = event.detail.user;
        if (user) {
            userData.email = user.email;
            userData.firstName = user.name.firstName;
            userData.lastName = user.name.lastName;
        }
        submitSocialLogin(userData.token, "apple");
      } 
      else setError("Failed to log in");  
    }

    if (!initialized.current) {
       
      console.log("You are running on REACT_APP_SERVER ", process.env.REACT_APP_SERVER);

      initialized.current = true

      /* global google */
      if (window.google) {
        google.accounts.id.initialize({
          client_id: config.GOOGLE_CLIENT_ID,
          callback: handleGoogle,
        });

        google.accounts.id.renderButton(document.getElementById("googleDiv"), {
          type: "standard",
          theme: "filled_blue",
          size: "large", 
          width:"200",
          text: "Sign in with Google",
          shape: "pill",
        });

        // google.accounts.id.prompt()
      }

      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId : config.APPLE_LOGIN_BUNDLE_ID,
          scope : 'email name',
          redirectURI : config.APPLE_REDIRECT_URI,
          state : 'SignInUserAuthenticationRequest',
          usePopup : true
        });

        
        document.addEventListener('AppleIDSignInOnSuccess', appleIDSignInOnSuccess)
        
        document.addEventListener('AppleIDSignInOnFailure', appleIDSignInOnFailure)
 
        
      } 

    }

    return () => {
      document.removeEventListener('AppleIDSignInOnFailure', appleIDSignInOnFailure);
      document.removeEventListener('AppleIDSignInOnSuccess', appleIDSignInOnSuccess);
    };
    // eslint-disable-next-line 
  }, []);
 
  
  useEffect(() => {
   
    const checked = JSON.parse(localStorage.getItem("persist")) || false;
    const loggedInUser = JSON.parse(localStorage.getItem("user"));  
    if (loggedInUser && checked) navigate("/qr")
    else logout()

    // eslint-disable-next-line 
  },[navigate]); 


  async function submitSocialLogin(token, provider){
    try { 
      setError("")
      setLoading(true)
      const res = await socialLogin(token, provider);
      if(res.error){
        console.log("submitSocialLogin error " , res.error)
        if (res.error.code === 603) {

          if (provider === 'apple' && !userData.firstName){
            setError(`Whoop! Invalid Sign up. Please remove QRow in 'Sign with Apple' from your icloud setting and try again.`)
            return; 
          }
          
          const result = await  socialSignup(token, provider, userData.email, userData.firstName, userData.lastName)
          if(result.error){
            if (result.error.message) setError(`Whoop! ${result.error.message}`)
            else setError(`Whoop! ${result.error}`)
          }
          else {
            if( qrCodeId !== "" ){
              navigate("/qr/"+qrCodeId)
            }
            else navigate("/qr")
          } 

        }
        else if (res.error.message) setError(`Whoop! ${res.error.message}`)
        else setError(`Whoop! ${res.error}`)
        
      }
      else {
        if( qrCodeId !== "" ){
          navigate("/qr/"+qrCodeId)
        }
        else navigate("/qr")
      } 
    } catch (error) {
      setError("Failed to log in")
    }
    finally{
      setLoading(false)
    }
  }
  async function handleGoogle (response){
    console.log("handleGoogle response ", response); 
    let decoded = await decodeJWTToken(response.credential );

    console.log("handleGoogle decoded ", decoded); 
    userData.token = response.credential;
    userData.email = decoded.email;
    userData.firstName = decoded.given_name;
    userData.lastName = decoded.family_name; 

    if (response.credential) submitSocialLogin(response.credential, 'google'); 
    else setError("Failed to log in")
  };

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      const res = await login(emailRef.current.value, passwordRef.current.value);

      if(persist){
       
        localStorage.setItem("email", emailRef.current.value);
        localStorage.setItem("password", passwordRef.current.value);

      }
      else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      

      if(res.error){
        if (res.error.message) setError(`Whoop! ${res.error.message}`)
        else setError(`Whoop! ${res.error}`)
        
      }
      else {
        if( qrCodeId !== "" ){
          navigate("/qr/"+qrCodeId)
        }
        else navigate("/qr")
      } 

    } catch {
      setError("Failed to log in")
    }
    finally{
      setLoading(false)
    }

    
  }
 
 

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4 form-title">Log In</h2>
           
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label className="gray-text">Email</Form.Label>
              <Form.Control type="text" ref={emailRef}  required className="small-text"/>
            </Form.Group>
            <Form.Group id="password">
              <Form.Label className="gray-text">Password  </Form.Label>
              <div className="showPasswordText">
                <Form.Control type={passwordType} ref={passwordRef} required />
                  <div className="eyeIcon" onClick={() => {
                    setShowPassword(prev => !prev);
                    setPasswordType(prev => prev === "text" ? "password" : "text")
                  }}> 
                    {
                    showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : 
                    <FontAwesomeIcon icon={faEye} />
                    }
                </div>
              </div>
              
             
            </Form.Group>

            <Form.Group className="mb-3" id="formBasicCheckbox">
              <Form.Check type="checkbox" className="gray-text" label="Trust this device" defaultChecked={persist} onChange={ () => {
                setPersist(prev => !prev);
                localStorage.setItem("persist", !persist);
              }}/>
            </Form.Group>

            {loading ? <Button variant="primary button-radius" disabled className="w-100 mt-3">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    Loading...
                  </Button> :  
                  <Button disabled={loading} className="w-100 mt-3 button-radius" type="submit">
                    Log In
                  </Button>
            }
          </Form>

          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <div className="w-100 text-center mt-3 ">
            <p>Or Start With</p> 
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col">
            <div id="appleid-signin"
                data-mode="center-align"
                data-type="sign-in"
                data-color="white"
                data-border="true"
                data-border-radius="50"
                data-width="200"
                data-height="40" 
                className="social-button"></div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col"></div> 
            <div className="col">
              <div id="googleDiv" ></div>
            </div>
            <div className="col"></div>
          </div>
 

          
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2"> 

        <h6 className="mt-20 gray-light"> DON'T HAVE AN ACCOUNT? <Link to="/signup" className="white-link">SIGN UP</Link> </h6>
      </div>
    </>
  )
}
