
import * as Realm from "realm-web";
import { jwtDecode } from "jwt-decode";
import React, { useContext, useState, useEffect } from "react"
import md5 from "md5";
import { Config } from "../config/Config"

let config = process.env.REACT_APP_SERVER ===  'dev' ? Config.DEV : Config.PROD;
// Add your App ID
const app = new Realm.App({ id:config.REALM_APP_ID });

const AuthContext = React.createContext()
 
export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {

  const [userData, setCurrentUserData] = useState()
  const [currentUser, setCurrentUser] = useState() 
  const [realmUser, setRealmUser] = useState(); 
  const [realmApp, setRealmApp] = useState()
  const [playNextAsset, setPlayNextAsset] = useState({})
  const [playingAsset, setPlayingAsset] = useState({})
  const [playedId, setPlayedId] = useState("")
  const [isPlayMediaFinished, setPlayMediaFinish] = useState("")
  const [isPlayMediaError, setPlayMediaError] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [qrCodeId, setQRCodeID] = useState("")

  const ADMIN_LIST = ["rckrueger@gmail.com", "tolacambo@gmail.com", "fcdavidson@gmail.com"];

  async function getUserData(data) {
    try {
      if (data['access-token']) {
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' , 'access-token': data['access-token'] || ""}
        };
    
        const response = await fetch(`${config.REST_API}/api/appuser/getUser`, requestOptions);
        let res =   await response.json();

        if (response.status !== 200){
          return {error:res}
        } 
        else{  
          let user = res; 
          if(res.metaData) {
            user.firstName = res.metaData.user_data.name ? res.metaData.user_data.name.first : "";
            user.lastName = res.metaData.user_data.name ? res.metaData.user_data.name.last : "";
          }
          setCurrentUserData(user) 
          return res;
        } 
      }
    } catch (error) {
      console.error(`getUserData error ${error}`);
      return {error:error}
    }
  }

  async function signup(email, password, firstName, lastName, code) {

    try {

      let metadata = {
        user_data:{
          name: {
            first: firstName,
            last: lastName
          }
      }}

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({ handle: email, password: md5(password), metaData:JSON.stringify(metadata), code: code })
      };
      
      let endpoint = code ? `${config.REST_API}/api/appuser/register` : `${config.REST_API}/api/appuser/signup`
      const response = await fetch(endpoint, requestOptions);
      let data =   await response.json();

      if (response.status !== 200){
        return {error:data}
      } 
      else{ 

        if (data.jwt){
          loginRealmWithCustomJwt(data.jwt); 
          setCurrentUser(data)
        } 

        return data;
      } 
    } catch (error) { 
      return  {error:error}
    } 

  }


  async function signupComplete(email, code) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({ handle: email, code:code })
      };
  
      const response = await fetch(`${config.REST_API}/api/appuser/completeSignup`, requestOptions);
      let data =   await response.json();

      if (response.status !== 200){
        return {error:data}
      } 
      else{ 

        if (data.jwt){
          loginRealmWithCustomJwt(data.jwt); 
          setCurrentUser(data)
        } 

        return data;
      } 
    } catch (error) {
      
    }
  }

  async function reloadRealm(){
    console.log("reloadRealm ....")
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const data = JSON.parse(loggedInUser);
      return await loginRealmWithCustomJwt(data.data)
    }
    //console.log("reloadRealm ....loggedInUser ", loggedInUser)
    return null

  }

  async function loginRealmWithCustomJwt(data) {
    // Create a Custom JWT credential
    //console.error("loginRealmWithCustomJwt .jwt: ", data);
    const credentials = Realm.Credentials.jwt(data.jwt);
    try {
      // Authenticate the user
      const user = await app.logIn(credentials);
      //Realm.App.Sync.setLogLevel(app, 'debug');
      
      // `App.currentUser` updates to match the logged in user
      console.assert(user.id === app.currentUser.id);
      //console.log("loginRealmWithCustomJwt success ", user.identities[0]);

      console.log("Login to MongodDB Realm: user.id ", user.id );


      setRealmUser(user);
      setRealmApp(app)
     
      // store the user in localStorage 
      const now = new Date()
      const userCache = {
        data: data,
        expiry: now.getTime() + 2000000,
      }; 
      localStorage.setItem('user', JSON.stringify(userCache));
     

      if (user.identities[0] && user.identities[0].id && ADMIN_LIST.indexOf(user.identities[0].id) >= 0){
        userCache.email = user.identities[0].id;
        localStorage.setItem('superuser', JSON.stringify(userCache));
      }

      await getUserData(data)

      console.log("Login to MongodDB Realm: userCache.email ", userCache.email);
      return user
      
    } catch (err) { 
      console.error("Failed to log in or sync data: ", err);
      localStorage.removeItem("user");
      return null;
    }
  }

  async function loginAnonymous(){

    try {
        const user = await app.logIn(Realm.Credentials.anonymous());
        setRealmUser(user);
        return user
    } catch (error) {
        console.error(error)
        return null
    }
   
      
  }

  async function decodeJWTToken(token) {
    var decoded = jwtDecode(token);
    console.log(decoded) 
    return decoded;
  }

  async function socialSignup(token, provider, email, firstName, lastName) {

    try {

      let metaData = {user_data:{name:{first:firstName,last:lastName}}};

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({ token: token, handle:email, provider: provider, metaData:JSON.stringify(metaData) })
      };
  
      const response = await fetch(`${config.REST_API}/api/appuser/socialSignup`, requestOptions);
     
      let data =   await response.json();
      console.log("socialSignup response data ", data)
      
      if (response.status !== 200){
        return {error:data}
      } 
      else{ 

        if (data.jwt){
           
          await loginRealmWithCustomJwt(data);  
        
          setCurrentUser(data)
          return data;
        }  
        else return data;
      } 

    } catch (error) {
      console.log(error)
      return {error:error}
    }
  }

  async function socialLogin(token, provider) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({ token: token, provider: provider })
      };
  
      const response = await fetch(`${config.REST_API}/api/appuser/socialLogin`, requestOptions);
      //console.log("login response ", response)
      let data =   await response.json();

      if (response.status !== 200){
        return {error:data}
      } 
      else{ 

        if (data.jwt){
           
          await loginRealmWithCustomJwt(data);  
        
          setCurrentUser(data)
          return data;
        }  
        else return data;
      } 
    } catch (error) {
      console.log(error)
      return {error:error}
    }
  }

  async function login(email, password) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({ handle: email, password: md5(password) })
      };
  
      const response = await fetch(`${config.REST_API}/api/appuser/login`, requestOptions);
      //console.log("login response ", response)
      let data =   await response.json();

      if (response.status !== 200){
        return {error:data}
      } 
      else{ 

        if (data.jwt){
           
          await loginRealmWithCustomJwt(data);  
        
          setCurrentUser(data)
          return data;
        }  
        else return data;
      } 
    } catch (error) {
      console.log(error)
      return {error:error}
    }
  }

  function logout() {
    setRealmUser();
    setCurrentUser();
    setCurrentUserData();
    localStorage.clear();
    return app.currentUser ? app.currentUser.logOut() : false;
  }

  async function requestResetPassword(email) {
    try {
       
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({ handle: email })
      };
  
      const response = await fetch(`${config.REST_API}/api/appuser/forgotPassword`, requestOptions);
      let data =   await response.json();

      if (response.status !== 200){
        return {error:data}
      } 
      else{ 
        return data;
      } 

    } catch (error) {
      return {error:error}
    }
  }

  async function resetPassword(email, password, code) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'app-token': config.APP_TOKEN},
        body: JSON.stringify({handle:email, password: md5(password), code:code })
      };
  
      const response = await fetch(`${config.REST_API}/api/appuser/resetPassword`, requestOptions);
      let data = await response.json();

      if (response.status !== 200){
        return {error:data}
      } 
      else{ 
        return data;
      } 

    } catch (error) {
      return {error:error};
    }
  }


  function updatePassword(password) {
    //return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      try {
        const foundUser = JSON.parse(loggedInUser); 
        setCurrentUser(foundUser)
      } catch (error) {
        
      }
     
    }
    
  }, [])

  const value = {
    currentUser,
    userData,
    realmUser, 
    realmApp,
    playingAsset,
    isPlayMediaFinished,
    playedId,
    playing,
    playNextAsset,
    isPlayMediaError,
    qrCodeId,
    setPlayNextAsset,
    setPlayedId,
    setPlayMediaError,
    setPlayMediaFinish,
    setPlaying, 
    setPlayingAsset,
    reloadRealm,
    loginAnonymous,
    login,
    socialLogin,
    socialSignup,
    decodeJWTToken,
    signup,
    signupComplete,
    logout, 
    requestResetPassword,
    resetPassword,
    updatePassword,
    setQRCodeID
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
