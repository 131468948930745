import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Signup() {

    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    
    const signupCode = useRef()
    const { signup } = useAuth()
    const { signupComplete } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [completeSignup, setCompleteSignup] = useState(false)
    const [loading, setLoading] = useState(false)

    const [showPassword, setShowPassword] = useState(false)
    const [passwordType, setPasswordType] = useState("password")

    const navigate = useNavigate()

    async function handleSubmit(e) {

      e.preventDefault()


      if( completeSignup) {
        if (signupCode.current.value === "") {
          return setError("Please enter verification code")
        }

        try {
          setError("")
          setLoading(true)
          let res = await signupComplete(emailRef.current.value, signupCode.current.value);
          if (res.error){
            setError(`Failed to verify account "${res.error.message}"`)
          } 
          else {
            navigate("/qr")
          }
        } catch {
          setError("Failed to create an account")
        }

      }
      else{
       

        try {
          setError("")
          setLoading(true)
          let res = await signup(emailRef.current.value, passwordRef.current.value, firstNameRef.current.value, lastNameRef.current.value, signupCode.current.value)
          if (res.error){
            setError(`Failed to create an account "${res.error.message}"`)
          }
          else if (res === true){
            
            setCompleteSignup(true)
            setMessage(`Please enter signup code from your email`)
          }
          else {
            navigate("/qr")
          }
        } catch {
          setError("Failed to create an account")
        }

      }  
    
       
    
        setLoading(false)
      }

    
    return (
        <>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Sign Up</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="info">{message}</Alert>}
              <Form onSubmit={handleSubmit}>

                <Form.Group id="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" ref={firstNameRef} required />
                </Form.Group>

                <Form.Group id="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" ref={lastNameRef} required />
                </Form.Group>

              
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" ref={emailRef} required className="small-text"/>
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>

                  <div className="showPasswordText">
                    <Form.Control type={passwordType} ref={passwordRef} required />
                      <div className="eyeIcon" onClick={() => {
                        setShowPassword(prev => !prev);
                        setPasswordType(prev => prev === "text" ? "password" : "text")
                      }}> 
                        {
                        showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : 
                        <FontAwesomeIcon icon={faEye} />
                        }
                    </div>
                  </div>
                  
                </Form.Group>
                 
                {
                  <div>
                    <Form.Group id="signupCode">
                      <Form.Label>Invited Code</Form.Label>
                      <Form.Control type="number" ref={signupCode} required />
                    </Form.Group>

                    
                  </div>
                }
                <Button disabled={loading} className="w-100 mt-3" type="submit">
                  REGISTER NOW
                </Button>

                <p className="w-100 text-center mt-2 gray-text">
                  By clicking on Register button, your agree to our <Link to="/termsofuse" className="white-link">Terms of use</Link> and that you have read our <Link to="/policy" className="white-link">Privacy Policy</Link>
                </p>

              </Form>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            Already have an account? <Link to="/login" className="white-link">LOGIN</Link>
          </div>
        </>
      )
}
