import { Container } from "react-bootstrap"
import React, { useEffect, useState, useRef } from 'react'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import ForgotPassword from './components/ForgotPassword';
import Header from './components/Header';
import Login from './components/Login'; 
import QRcode from './components/QRcode';
import Signup from './components/Signup';
import { Routes, Route } from "react-router-dom" 
import { AuthProvider } from './contexts/AuthContext';
import Footer from './components/Footer';
import TermCondition from "./components/TermCondition";
import Privacy from "./components/Privacy";
import Dashboard from "./components/Dashboard";
import { useAuth } from "./contexts/AuthContext"
import { useQR } from "./contexts/QRContext"

function App() {

  const { setQRCodeID, loginAnonymous, realmApp, realmUser, reloadRealm } = useAuth();
  const { redirecting, setRedirecting, setQrLoadError, setLoading, setQrCode } = useQR();
  const {id} = useParams();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const reload = () => window.location.reload();
  
  useEffect(() => { 

    console.log(`Detecting qr route for ${id}`)

    if (id === undefined) {
      setRedirecting(false)
      return;
    }
    setLoading(false);
    setQRCodeID(id);
    fetchQRCode();
  }, [])

  const fetchQRCode = async () => { 
    
    try { 
      var user;
    
      // Figure out what context we are querying Realm
      if (realmUser) {
          user = realmUser;
      }
      else if (loggedInUser) {
        user = await reloadRealm(); 
 
      }
      else {
        user = realmApp && realmApp.currentUser ?  realmApp.currentUser : await loginAnonymous();
      }

      // Fetch the qr info
      const payload = `https://qrow.io/qr/${id}`;
      const result = await user.functions.consumeQRCode(payload);
      console.log(`consumeQRCode ${result}`)
      let code = JSON.parse(result)

      if (!code.error) {
        if (code.qrCodeType === 'link') {
            var link = "";
            if (code.content !== "") {
              link = code.content
              window.location.href = link;
            }
        }
        else {
          setRedirecting(false)
          setQrCode(code);
          console.log('Not a link, continue to render...')
        }
      }
      else {
        setQrLoadError("Invalid QR")
        
      }
    } catch (error) {
      setQrLoadError("Unable to load QR");
      console.error(error)
    }
    finally{
      setRedirecting(false)
    }
    
  }

  return (
    <>
      {
        !redirecting &&
          <div className="app">  
            <Header/>
            <Container className="d-flex align-items-center justify-content-center mt-4" >

              <div className="w-100" style={{ maxWidth: "800px" }}> 
                
                <Routes>
                  
                    <Route path="/" element={<QRcode/>} />

                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/forgot-password" element={<ForgotPassword/>} />
                    <Route path="/qr" element={<QRcode /> }/> 
                    <Route path="/qr/:id/*" element={<QRcode /> }/>
                    <Route path="/apple-app-site-association" onEnter={reload} />
                    <Route path="/termsofuse" element={<TermCondition /> }/> 
                    <Route path="/policy" element={<Privacy /> }/> 
                    <Route path="/dashboard" element={<Dashboard /> }/> 
                    <Route path="*" element={<QRcode/>} /> 
                  
                </Routes>
                    
              </div>
              
            </Container>
          </div>
      }
      {
        redirecting &&
        <Routes>
          <Route path="*" element={<QRcode/>} />
        </Routes>
      }
    </>
  );
}

export default App;
