import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Spinner } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom" 

export default function ForgotPassword() { 

  const emailRef = useRef()
  const codeRef = useRef()
  const passwordRef = useRef()
  const {requestResetPassword, resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [info, setInfo] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate() 
  const [confirmStep, setConfirmStep] = useState(1)

  useEffect(() => {
    setConfirmStep(false);
  },[])


  async function handleSubmit(e) {
    e.preventDefault() 

    try {
      setError("")
      setInfo("")
      setLoading(true)
      if(!confirmStep){
        let result = await requestResetPassword(emailRef.current.value); 

        if(result.error){
          setError(`Failed to reset password "${result.error.message}"`)
        }
        else{
          setInfo("Please check your email for 6 digits code.");
          setConfirmStep(true);
        } 

        
      }
      else {
        let result = await resetPassword(emailRef.current.value, passwordRef.current.value, codeRef.current.value); 
        if(result.error){
          setError(`Failed to reset password "${result.error.message}"`)
        }
        else navigate("/login")
      }
      
      

    } catch (error){
      console.error(error)
      setError("Failed to reset password")
    }
    finally{
      setLoading(false)
    }

    
  }

   

  return (
    <>
   

      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Reset Password</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {info && <Alert variant="info">{info}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required  onChange={ () => {}}/>
            </Form.Group>
            { confirmStep &&
              <>
                <Form.Group id="code">
                  <Form.Label>Confirmed Code</Form.Label>
                  <Form.Control type="number" ref={codeRef}  required  onChange={ () => {}}/>
                </Form.Group>

                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" ref={passwordRef}  required  onChange={ () => {}}/>
                </Form.Group>
              </>
            }

            {loading ? <Button variant="primary" disabled className="w-100 mt-3">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    Loading...
                  </Button> :  
                  <Button disabled={loading} className="w-100 mt-3" type="submit">
                    Submit
                  </Button>
            }
          </Form>
          
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup" className="white-link">SIGNUP</Link>
      </div>
       
      
    </>
 
  )
}
